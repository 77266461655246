@import './assets/style/bootstrap-override.scss';
@import "./assets/style/fontello-embedded.scss";

.App {
  text-align: center;
}

.logo-minpros-image {
  @media (max-width: ($lg - 1)) {
    width: 143px;
  }

  background-image: url('./assets/images/logo-minpros.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 186px;
  height: 98px;
}

.logo-arsa-image {
  @media (max-width: ($lg - 1)) {
    width: 78%;
  }

  background-image: url('./assets/images/logo-arsa.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 102px;
  height: 72px;
}

.logo-cms-image {
  @media (max-width: ($lg - 1)) {
    width: 136px;
  }

  background-image: url('./assets/images/logo-cms.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 178px;
  height: 52px;
}

.title-image {
  background-image: url('./assets/images/main-title.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 820px;
  height: 142px;
}

.header-right-image {
  background-image: url('./assets/images/cock-white.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 240px;
  height: 240px;
  background-position: right center;
  max-width: 240px;
}

.arrow1-image {
  background-image: url('./assets/images/arrow1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;

  width: 63px;
  height: 154px;
}

.arrow2-image {
  background-image: url('./assets/images/arrow2.png');
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 95px;
  height: 167px;
}

.arrow3-image {
  background-image: url('./assets/images/arrow3.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 272px;
  height: 193px;
}

.separator-image {
  @media (max-width: ($sm - 1)) {
    height: 64px;
  }

  background-image: url('./assets/images/separator.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 1230px;
  height: 184px;
}

.wheel-image {
  background-image: url('./assets/images/wheel.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 520px;
  height: 520px;
}

.motanka-image {
  background-image: url('./assets/images/motanka.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 533px;
  height: 537px;
}

.matryoshka-image {
  background-image: url('./assets/images/matryoshka.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 380px;
  height: 434px;
}

.cock-image {
  background-image: url('./assets/images/cock-colored.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 136px;
  height: 182px;
}

.samovar-image {
  background-image: url('./assets/images/samovar.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 1065px;
  height: 670px;
}

.sun-big-anim-image {
  background-image: url('./assets/images/sun-big-anim.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 614px;
  height: 566px;
}

.sun-big-stat-image {
  background-image: url('./assets/images/sun-big-stat.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 323px;
  height: 322px;
}

.sun-big-image {
  background-image: url('./assets/images/sun-big.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 985px;
  height: 985px;
}

.sun-left-image {
  background-image: url('./assets/images/sun-left.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 234px;
  height: 386px;
}

.sun-right-image {
  background-image: url('./assets/images/sun-right.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 365px;
  height: 589px;
}

.label1-image {
  background-image: url('./assets/images/label1.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 1099px;
  height: 159px;
}

.label2-image {
  background-image: url('./assets/images/label2.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 1192px;
  height: 128px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}