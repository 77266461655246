.video-block {
    margin-top: 1rem;
    margin-bottom: 2rem;

    .button-text {
        font-family: Evolventa;
        font-size: 24px;
        font-weight: 400;
        line-height: 43px;
        letter-spacing: -0.02em;
    }

    video {
        border-radius: 10px;
    }
}
