$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

.main-header {
    height: 323px;
    background: #0B1D8A;

    .main-container {
        @media (max-width: ($md - 1)) {
            flex-direction: column;
            justify-content: flex-start;
        }

        display: flex;
        align-items: center;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .logo-block {
            @media (max-width: ($md - 1)) {
                flex-direction: row;
                width: 100%;
                max-width: 100%;
                align-items: center;
                max-height: 100px;
            }

            display: flex;
            flex-direction: column;
            align-items: left;
            justify-content: space-evenly;
            text-decoration: none;
            height: 100%;
            max-width: 202px;
            left: 49px;
            top: 16px;

            .logo-item {
                background-position: center center;
            }
        }

        .title-block {
            @media (max-width: ($md - 1)) {
                margin-left: 0;
                margin-right: 0;
                max-height: 196px;
                line-height: 54px;
            }

            font-family: Vezitsa;
            font-size: 60px;
            line-height: 60px;
            letter-spacing: 0em;
            text-align: center;
            color: #FFFFFF;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            margin-left: 2rem;
            margin-right: 2rem;

            min-width: 60%;

            .title-block-image {
                @media (max-width: ($sm - 1)) {
                    margin-top: -2rem;
                    margin-bottom: -2rem;
                }

                width: 100%;
                background-position: center center;
            }

            .title-text1 {
                @media (max-width: ($xl - 1)) {
                    font-size: 54px;
                }

                @media (max-width: ($lg - 1)) {
                    font-size: 48px;
                }

                @media (max-width: ($md - 1)) {
                    font-size: 54px;
                }

                @media (max-width: ($sm - 1)) {
                    font-size: 38px;
                }
            }

            .title-text2 {
                @media (max-width: ($xl - 1)) {
                    font-size: 45px;
                }

                @media (max-width: ($lg - 1)) {
                    font-size: 42px;
                }

                @media (max-width: ($md - 1)) {
                    font-size: 48px;
                }

                @media (max-width: ($sm - 1)) {
                    font-size: 35px;
                }
            }
        }

        .right-block {
            @media (max-width: ($md - 1)) {
                display: none;
            }
        }
    }
}