$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

.footer {
    position: relative;
}

.footer-top {
    margin-top: 1rem;
    font-size: 36px;
    

    i {
        color: #09155D;
    }

    .phone {
        display: flex;
        flex-direction: row;

    }
}

.footer-bottom {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.footer-copyright {
    @media (max-width: ($lg - 1px)) {
        font-size: 13px;
    }

    @media (max-width: ($sm - 1px)) {
        font-size: 12px;
    }

    color:var(--label);
    font-family: Evolventa;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.078px;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
}