@import 'bootstrap';

$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

.btn-primary {
    --bs-btn-bg: #09155D;
    --bs-btn-border-color: #09155D;
    --bs-btn-hover-bg: #11269f;
    --bs-btn-hover-border-color: #11269f;
}

// $enable-negative-margins: true;

// .container {
//     @media (min-width: $sm) {
//         max-width: 560px;
//     }
//     @media (min-width: $md) {
//         max-width: 740px;
//     }
//     @media (min-width: $lg) {
//         max-width: 980px;
//     }
//     @media (min-width: $xl) {
//         max-width: 1160px;
//     }
//     @media (min-width: $xxl) {
//         max-width: 1380px;
//     }
//     // max-width: 95%;
// }

// .row {
//     flex-direction: row;
// }

// .col {
//     margin-left: 1rem;
//     margin-right: 1rem;
// }

// .navbar {
//     --bs-navbar-hover-color: #FFFFFF;

//     position: absolute;
//     margin-left: 0;
//     padding-left: 0;
//     left: 5%;
//     top: 30%;
// }

// .navbar-mobile {
//     align-items: flex-start;
// }

// .navbar-nav-scroll {
//     max-height: 560px;
// }

// .navbar-dark {
//     --bs-navbar-color: transparent;
//     --bs-navbar-toggler-border-color: white;
// }

// .navbar-collapse {
//     flex-basis: auto;
// }