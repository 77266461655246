@font-face {
    font-family: 'Vezitsa';
    src: local('Vezitsa'), local('Vezitsa Cyrillic'),
        url('vezitsacyrillic.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Evolventa';
    src: local('Evolventa'), local('Evolventa Regular'),
        url('Evolventa-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}