$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

.main-block {
    margin-top: 2rem;

    background-image: url("../assets/images/bg-pattern.png");
    background-size: contain;

    display: flex;
    flex-direction: column;
    align-items: center;

    .text1 {
        @media (max-width: ($xl - 1)) {
            font-size: 35px;
            font-weight: 900;
            line-height: 54px;
        }
    
        @media (max-width: ($lg - 1)) {
            font-size: 54px;
            font-weight: 700;
            line-height: 70px;
        }
    
        @media (max-width: ($md - 1)) {
            font-size: 35px;
            font-weight: 900;
            line-height: 54px;
        }
    
        @media (max-width: ($sm - 1)) {
            font-size: 31px;
            font-weight: 700;
            line-height: 46px;
        }
    
        font-family: Evolventa;
        font-size: 54px;
        font-weight: 700;
        line-height: 70px;
        letter-spacing: 0px;
        color: #09155D;
    }
    
    .text2 {
        @media (max-width: ($xl - 1)) {
            font-size: 22px;
            line-height: 33px;
        }
    
        @media (max-width: ($lg - 1)) {
            font-size: 34px;
            line-height: 43px;
        }
    
        @media (max-width: ($md - 1)) {
            font-size: 22px;
            line-height: 33px;
        }
    
        @media (max-width: ($sm - 1)) {
            font-size: 18px;
            line-height: 30px;
        }
    
        font-family: Evolventa;
        font-size: 34px;
        font-weight: 400;
        line-height: 43px;
        letter-spacing: -0.02em;
        color: #09155D;
    }
    
    .text3 {
        @media (max-width: ($xl - 1)) {
            font-size: 60px;
        }
    
        @media (max-width: ($lg - 1)) {
            font-size: 80px;
        }
    
        @media (max-width: ($md - 1)) {
            font-size: 60px;
        }
    
        @media (max-width: ($sm - 1)) {
            font-size: 56px;
        }
    
        font-family: Vezitsa;
        font-size: 80px;
        font-weight: 400;
        line-height: 80px;
        letter-spacing: 0em;
        color: #09155D;
    }

    .infoblock {
        @media (max-width: ($lg - 1)) {
            flex-direction: column;
        }
    
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        .text-block-with-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
        
            &-texts {
                display: flex;
                justify-content: center;
                flex-direction: column;
                max-width: 706px;
            }
        }

        .block1 {
            @media (max-width: ($lg - 1)) {
                width: 100%;
            }
        
            width: 60%;
        }
        
        .block2 {
            @media (max-width: ($lg - 1)) {
                width: 100%;
            }
        
            width: 80%;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    .arrow {
        @media (max-width: ($sm - 1)) {
            display: none;
        }
    }
    
    .arrow2 {
        margin-top: 32px;
    }
    
    .arrow3 {
        @media (max-width: ($lg - 1)) {
            display: none;
        }
    
        position: absolute;
        right: 1%;
        top: 20%;
        transform: translate(0, -50%);
    }
    
    .wheel {
        @media (max-width: ($md - 1)) {
            width: 390px;
            height: 390px;
        }
    
        @media (max-width: ($sm - 1)) {
            width: 260px;
            height: 260px;
        }
    
        max-width: 100%;
    }
    
    .wheel-anim {
        animation: wheel-rotate 5s infinite;
    }
    
    @keyframes wheel-rotate {
        0% {
            transform: rotate(0deg);
        }
    
        10% {
            transform: rotate(60deg);
        }
    
        20% {
            transform: rotate(60deg);
        }
    
        30% {
            transform: rotate(120deg);
        }
    
        40% {
            transform: rotate(120deg);
        }
    
        50% {
            transform: rotate(180deg);
        }
    
        60% {
            transform: rotate(180deg);
        }
    }
    
    .separator {
        @media (max-width: ($md - 1)) {
            width: 90%;
        }
    
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
    }
    
    .motanka {
        max-width: 100%;
        background-size: contain;
    }

    .centered-block {
        @media (max-width: ($lg - 1)) {
            margin-top: 6rem;
        }
    
        @media (max-width: ($sm - 1)) {
            margin-top: 0;
            height: 366px;
        }
    
        position: relative;
        height: 468px;
    }
    
    .sun-big-stat-image {
        @media (max-width: ($md - 1)) {
            transform: scale(0.7);
        }
    
        @media (max-width: ($sm - 1)) {
            transform: scale(0.45);
        }
    
        position: relative;
        background-position: center;
        background-size: cover;
    }
    
    .sun-big-anim-image {
        position: absolute;
        left: 50%;
        top: 50%;
    }
    
    .sun-anim {
        animation: sun-rotate 4s infinite;
        mask-image:
            url('../assets/images/sun-big-anim.png');
        -webkit-mask-image:
            url('../assets/images/sun-big-anim.png');
    }
    
    @keyframes sun-rotate {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
    
        10% {
            background:
                linear-gradient(rgba(26, 42, 136, 0.2), rgba(255, 8, 0, 0.2)), url('../assets/images/sun-big-anim.png');
        }
    
        20% {
            background:
                linear-gradient(rgba(26, 42, 136, 0.4), rgba(255, 8, 0, 0.4)), url('../assets/images/sun-big-anim.png');
        }
    
        30% {
            background:
                linear-gradient(rgba(26, 42, 136, 0.6), rgba(255, 8, 0, 0.6)), url('../assets/images/sun-big-anim.png');
        }
    
        40% {
            background:
                linear-gradient(rgba(26, 42, 136, 0.8), rgba(255, 8, 0, 0.8)), url('../assets/images/sun-big-anim.png');
        }
    
        50% {
            background:
                linear-gradient(#1A2A88ff, #ff0800ff);
            transform: translate(-50%, -50%) rotate(45deg);
        }
    
        60% {
            background:
                linear-gradient(rgba(26, 42, 136, 0.8), rgba(255, 8, 0, 0.8)), url('../assets/images/sun-big-anim.png');
        }
    
        70% {
            background:
                linear-gradient(rgba(26, 42, 136, 0.6), rgba(255, 8, 0, 0.6)), url('../assets/images/sun-big-anim.png');
        }
    
        80% {
            background:
                linear-gradient(rgba(26, 42, 136, 0.4), rgba(255, 8, 0, 0.4)), url('../assets/images/sun-big-anim.png');
        }
    
        90% {
            background:
                linear-gradient(rgba(26, 42, 136, 0.2), rgba(255, 8, 0, 0.2)), url('../assets/images/sun-big-anim.png');
        }
    
        100% {
            transform: translate(-50%, -50%);
        }
    }

    .cock1 {
        @media (max-width: ($lg - 1)) {
            display: none;
        }
    
        position: absolute;
        right: -100%;
        top: 40%;
        transform: translate(0, -50%);
    }
    
    .cock2 {
        @media (max-width: ($lg - 1)) {
            display: none;
        }
    
        position: absolute;
        left: 12%;
        top: 50%;
        transform: translate(0, -50%) scaleX(-1);
    }

    .sun-right {
        @media (max-width: ($md - 1)) {
            display: none;
        }
    
        position: absolute;
        right: 0;
        top: 40%;
        transform: translate(0, -50%);
        z-index: -1;
    }
    
    .sun-left {
        position: absolute;
        left: 0;
        top: 40%;
        transform: translate(0, -50%);
        z-index: -1;
    }

    .video-container {
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 100%;
        position: relative;

        .video-row {
            @media (max-width: ($lg - 1)) {
                display: block;
                flex: 1 0;
            }
        
            justify-content: center;
        }
        
        .video-col-1 {
            @media (max-width: ($lg - 1)) {
                max-width: 100%;
            }
        
            max-width: 50%;
        }
    }

    .matryoshka-block {
        height: 402px;
    }
    
    .matryoshka {
        @media (max-width: ($sm - 1)) {
            width: 266px;
            height: 304px;
        }
    
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        animation: matryoshka-rotate 2s infinite;
    
    }
    
    @keyframes matryoshka-rotate {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
    
        }
    
        50% {
            transform: translate(-50%, -50%) rotate(-25deg);
    
        }
    }

    .label1 {
        @media (max-width: ($md - 1)) {
            height: 120px;
        }
    
        max-width: 100%;
    
    }
    
    .label2 {
        max-width: 100%;
    
        margin-top: 2em;
    }

    .results-block {
        @media (max-width: ($md - 1)) {
            height: 86px;
            margin-bottom: 2rem;
        }
    
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        height: 240px;
    }
    
    .vertical-center {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    
    .down-block {
        max-width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    
    }
    
    .sun-big {
        max-width: 100%;
        position: absolute;
        left: 50%;
        top: 36%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: -1;
    }
    
    .samovar {
        @media (max-width: ($md - 1)) {
            transform: scaleX(1) scaleY(1);
            height: 384px;
            margin-top: 0;
            margin-bottom: 2rem;
        }
    
        max-width: 100%;
        margin-top: 6em;
        transform: scaleX(0.7) scaleY(0.7);
    }
}